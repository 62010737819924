var app = {

  init: function() {
    app.slider();
    app.form();
  }, // init END

  form: function() {

    //E-mail Ajax Send
    $("form").submit(function() { //Change
      var th = $(this);

      $.ajax({
        type: "POST",
        url: "mail.php", //Change
        data: th.serialize()
      }).done(function() {
          alert("Thank you!");
          setTimeout(function() {
            // Done Functions
            th.trigger("reset");
          }, 1000);
        });

      return false;
    });

  }, // menu END

  slider: function() {

    var $slider = new Swiper('#intro-slider', {
      slidesPerView: 1,
      pagination: {
        el: '#intro-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
                 ' из ' +
                 '<span class="' + totalClass + '"></span>';
        },
      },
      navigation: {
        nextEl: '#intro-button-next',
        prevEl: '#intro-button-prev',
      },
    });

    var $slider = new Swiper('#review-slider', {
      slidesPerView: 3,
      speed: 400,
      centeredSlides: true,
      initialSlide: 1,
      pagination: {
        el: '#review-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return '<span class="' + currentClass + '"></span>' +
                ' из ' +
                '<span class="' + totalClass + '"></span>';
        },
      },
      navigation: {
        nextEl: '#review-button-next',
        prevEl: '#review-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 30,
          centeredSlides: false,
          initialSlide: 0
        }
      },
    });

    var $slider = new Swiper('#partners-slider', {
      slidesPerView: 5,
      spaceBetween: 30,
      navigation: {
        nextEl: '#partners-next',
        prevEl: '#partners-prev',
      },
      breakpoints: {
        360: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 15
        }
      },
    });

    var $catalogSlider01 = new Swiper('#catalog-slider01', {
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        nextEl: '#catalog-slider-next01',
        prevEl: '#catalog-slider-prev01',
      },
      breakpoints: {
        576: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 20
        },
      },
    });

    var $catalogSlider02 = new Swiper('#catalog-slider02', {
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        nextEl: '#catalog-slider-next02',
        prevEl: '#catalog-slider-prev02',
      },
      breakpoints: {
        576: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 20
        },
      },
    });

    var $catalogSlider03 = new Swiper('#catalog-slider03', {
      slidesPerView: 3,
      spaceBetween: 30,
      navigation: {
        nextEl: '#catalog-slider-next03',
        prevEl: '#catalog-slider-prev03',
      },
      breakpoints: {
        576: {
          slidesPerView: 1,
          spaceBetween: 0
        },
        1200: {
          slidesPerView: 2,
          spaceBetween: 20
        },
      },
    });

  } // slider END
}

jQuery(document).ready(function($) {
  console.log( 'Документ и все ресурсы загружены' );
  app.init();

  $('.brand-tooltip').tooltipster({
    animation: 'fade',
    theme: 'tooltipster-shadow',
    contentAsHTML: true,
    interactive: true,
    contentCloning: true,
    trigger: 'hover',
        // height: 200,
        // width: 200
    
    // origin: {
    //   size: {
    //   },
    // },
    // side: 'top'
    // functionPosition: function(instance, helper, data){
        
    //     // this function is pretty dumb and does not check if there is actually
    //     // enough space available around the tooltip to move it, it just makes it
    //     // snap to the grid. You might want to do something smarter in your app!
        
    //     var gridBcr = $('#demo-position-grid')[0].getBoundingClientRect(),
    //       arrowSize = parseInt($(helper.tooltipClone).find('.tooltipster-box').css('margin-left'));
        
    //     // override these
    //     data.coord = {
    //       // move the tooltip so the arrow overflows the grid
    //       left: gridBcr.left - arrowSize,
    //       top: gridBcr.top
    //     };
        
    //     return data;
    //   },
    //   maxWidth: 228,
    //   side: ['right']
    });


});
